/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
const PdfConversion = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-header.js"))

  useScript(withPrefix("animations/pdfconversion/pdfconversion.js"))

  useScript(withPrefix("animations/pdfconversion/pdfconversionlotte.js"))
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/pdf-conversion/",
        name: "Free eSign PDF Conversion Online | Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/pdf-conversion/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Convert your Word document into PDF online &amp; eSign PDF with Electronic Signature.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/pdf-conversion/"],
          },
        ],
      },
    ],
  }
  return (
    <>
      <Layout>
        <SEO
          title="Free eSign PDF Conversion Online | Document eSign"
          description="Convert your Word document into PDF online &amp; eSign PDF with Electronic Signature."
          schemaMarkup={schema}
        />

        <div className="feature-details pdf-conversion">
          <div className="intro show-on-scroll ani-in">
            <div className="container position-relative mb-md-n5">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center pt-lg-5">
                  <div className="h4 pt-5 pb-3">ESIGN PDF CONVERSION</div>
                  <h1 className="mb-lg-n4">
                    Free eSign PDF conversion online <br /> with Document eSign
                  </h1>
                </div>
                <div className="col-md-6 offset-md-3 mt-md-n5 text-center">
                  <div
                    id="pdfconversion_lottie_container"
                    className="feature-lottie-ani"
                  ></div>
                </div>
              </div>
            </div>
            <div className="bg-shape d-none d-lg-block">
              <svg
                width="2560px"
                height="100px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="none"
                x="0px"
                y="0px"
                viewBox="0 0 2560 100"
                xmlSpace="preserve"
                enableBackground="new 0 0 2560 100"
              >
                <polygon points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </div>
          <div className="container mt-md-5 pt-md-5">
            <div className="row pt-md-4">
              <div className="col-md-6 offset-md-3 text-center show-on-scroll ani-in">
                <p className="text-light-100 pt-4 pt-md-0">
                  Document esign PDF conversion lets you convert any Word
                  document to a PDF file in seconds to send and sign from almost
                  anywhere, on most devices. Electronically signing PDF
                  documents is fast, simple and easy without draining any
                  resources from your computer or electronic device. Document
                  esign PDF conversion provides you with an adaptable, shareable
                  format that is simple for everybody to access and displays
                  effectively on any screen.
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark mt-2 mb-2 px-md-5"
                >
                  Get started
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="card features-bx mb-5">
              <img
                src={withPrefix("images/pdf-top-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt=".."
              />

              <div className="card-body px-lg-5 mt-md-n5 py-0">
                <div className="row">
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-file-pdf"></i>
                    </div>

                    <div className="h4">Convert your documents</div>
                    <p className="text-light-100">
                      Electronically convert PDF documents with the built-in PDF
                      converter from Microsoft 365.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="far fa-window-maximize"></i>
                    </div>

                    <div className="h4">Browser support</div>
                    <p className="text-light-100">
                      Our ESign PDF conversion does not require to install
                      software and works well with all devices and popular
                      browsers: IE, Firefox, Chrome &amp; Opera.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-globe-asia"></i>
                    </div>

                    <div className="h4">Convert from anywhere</div>
                    <p className="text-light-100">
                      Work from anywhere and convert fillable PDF documents with
                      Document Esign.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-user-edit"></i>
                    </div>
                    <div className="h4">Save your edits</div>
                    <p className="text-light-100">
                      Document Esign free PDF conversion allows you save the
                      original format and design and convert your PDFs with the
                      highest quality fonts and precise formatting information
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature-bx-cta text-center show-on-scroll ani-in">
                <Link to="/features/" className="btn btn-outline-dark">
                  Explore more features{" "}
                  <i className="fas fa-arrow-right pl-2"></i>
                </Link>
              </div>
              <img
                src={withPrefix("images/pdf-bottom-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PdfConversion
